import "./AutocompleteDropdown.css"
import {useFormContext} from "react-hook-form";
import {useState} from "react";
import {places, placesSet} from "./places";


export default function AutocompleteDropdown({ id, placeholder, inputValue, setInputValue, activeAutocomplete, setActiveAutocomplete, required = false, width = "100%" }) {
    const { register } = useFormContext()
    const [validStyleClass, setValidStyleClass] = useState("valid-style");

    let autocompleteList;
    if (inputValue[1] && activeAutocomplete === id) {
        autocompleteList = (
            <div className="autocomplete-item">
                {places.reduce((filtered, place) => {
                    if (place.name.toLowerCase().startsWith(inputValue[1].toLowerCase())) {
                        filtered.push(
                            <div key={place.id} className="autocomplete-elem" onClick={() => {
                                setInputValue([place.id, place.name]);
                                setActiveAutocomplete(null);
                            }}>{place.name}</div>
                        )
                    }
                    return filtered;
                }, [])}
            </div>
        )
    } else {
        autocompleteList = (<></>)
    }

    return (
        <div className="autocomplete" style={{width: width}}>
            <input
                id={id}
                type="text"
                placeholder={placeholder}
                className={validStyleClass}
                value={inputValue[1]}
                {...register(id, {
                    validate: () => {
                        if (required) {
                            if (inputValue[1] && placesSet.has(inputValue[1].toLowerCase())) {
                                setValidStyleClass("valid-style")
                                return true
                            }
                        } else {
                            if (!inputValue[1] || placesSet.has(inputValue[1].toLowerCase())) {
                                setValidStyleClass("valid-style")
                                return true
                            }
                        }

                        setValidStyleClass("invalid-style")
                        setTimeout(() => {
                            setValidStyleClass("valid-style")
                        }, 5000);
                        return 'required'
                    },
                })}
                onInput={(e) => {
                    setInputValue([0, e.target.value]);
                }}
                onClick={() => setActiveAutocomplete(id)}
            />
            {autocompleteList}
        </div>
    )
}
