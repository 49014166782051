import {Col, Image} from "react-bootstrap";
import {Link} from "react-router-dom";


export default function IconLink(props) {
    function onClickChange() {
        props.setActive(props.index)
    }

    return (
        <Col
            xs={3} md={3} xl={3}
            className="link-icon"
            onClick={onClickChange}
            style={{ backgroundColor: props.active === props.index ? "#939394" : "#d4d4d7"}}
        >
            <Link to={props.linkTo}><Image  src={props.src} alt="" width="40%"></Image><p>{props.text}</p></Link>
        </Col>
    )
}
