import AutocompleteDropdown from "./AutocompleteDropdown";
import {useEffect, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {Col, Container, Row} from "react-bootstrap";
import Input from "./Input";


export default function FilterBar(props) {
    const methods = useForm();
    const [activeAutocomplete, setActiveAutocomplete] = useState(null);
    const [inputStartCityValue, setInputStartCityValue] = useState([0, ""]);
    const [inputEndCityValue, setInputEndCityValue] = useState([0, ""]);
    const [beginIntervalValue, setBeginIntervalValue] = useState(null);
    const [endIntervalValue, setEndIntervalValue] = useState(null);

    useEffect(() => {
        methods.trigger().then((value) => {
            if (value) {
                props.setFilters([inputStartCityValue, inputEndCityValue, beginIntervalValue, endIntervalValue])
            }
        })
    }, [inputStartCityValue, inputEndCityValue, beginIntervalValue, endIntervalValue])

    return (
        <Container>
            <FormProvider {...methods}>
                <Row>
                    <AutocompleteDropdown
                        id="start_place"
                        placeholder="Город отправления"
                        activeAutocomplete={activeAutocomplete}
                        setActiveAutocomplete={setActiveAutocomplete}
                        inputValue={inputStartCityValue}
                        setInputValue={setInputStartCityValue}
                        width="50%"
                    />
                    <AutocompleteDropdown
                        id="end_place"
                        placeholder="Город назначения"
                        activeAutocomplete={activeAutocomplete}
                        setActiveAutocomplete={setActiveAutocomplete}
                        inputValue={inputEndCityValue}
                        setInputValue={setInputEndCityValue}
                        width="50%"
                    />
                </Row>
                <Row>
                    <span>Интервал дат:</span>
                </Row>
                <Row>
                    <Col xs={6} md={6} xl={6}>
                        <input name="begin_interval" type="date" onInput={(e) => {setBeginIntervalValue(e.target.value)}} id="begin_interval" style={{width: "100%"}}/>
                    </Col>
                    <Col xs={6} md={6} xl={6}>
                        <input name="end_interval" type="date" onInput={(e) => {setEndIntervalValue(e.target.value)}} id="end_interval" style={{width: "100%"}} placeholder="фывфывфыв"/>
                    </Col>
                </Row>
            </FormProvider>
        </Container>
    )
}