import { useOutletContext } from "react-router-dom";

import Sender from "./Sender";


export default function SenderTab() {
    const [userInviteLink] = useOutletContext();

    return (<Sender type="senders" userInviteLink={userInviteLink}/>)
}
