import {useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import './Sender.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import FilterBar from "./FilterBar";
import SenderDetail from "./SenderDetail";
import Modal from 'react-modal';


export default function Sender(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const observerTarget = useRef(null);
    const [filters, setFilters] = useState([null, null, null, null]);

    const customStyles = {
        content: {
            inset: 0,
            // marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            background: window.Telegram.WebApp.colorScheme === "dark" ? "rgba(1,32,108,0.6)" : "rgba(124,124,124,0.6)",
            display: "flex",
            alignItems: "center",
        },
    };

    Modal.setAppElement('#root');

    useEffect(() => {
        if (Object.keys(item).length !== 0) {
            setIsOpen(true)
        }
        else {
            setIsOpen(false)
        }
    }, [item])

    function onClick(item) {
        item["type"] = props.type
        item["photo_url"] = `${process.env.REACT_APP_SERVER_DOMAIN}/api/v0/get-photo/${item["user_id"]}`
        setItem(item)
    }

    const fetchData = (() => {
        setIsLoading(true);
        setError(null);

        const filterParams = {}
        if (filters[0]) {
            if (filters[0][0]) {
                filterParams["start_city_id"] = filters[0][0];
            }
            if (filters[1][0]) {
                filterParams["end_city_id"] = filters[1][0];
            }
            if (filters[2]) {
                filterParams["begin_interval"] = filters[2] + "T00:00:00.000Z";
            }
            if (filters[3]) {
                filterParams["end_interval"] = filters[3] + "T00:00:00.000Z";
            }
        }

        fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/v0/${props.type}?` + new URLSearchParams({
            page: page,
            limit: 20,
            ...filterParams
        }).toString(), {credentials: "same-origin"})
            .then(response => response.json())
            .then(json => {
                setItems(prevItems => [...prevItems, ...json]);
                setPage(prevPage => prevPage + 1);
            }).catch(error => console.error(error))
            .finally(() => {
                setIsLoading(false)
            });
    });

    useEffect(() => {
        if (page === 1) {
            setItems([])
            fetchData();
        }
    }, [page])

    useEffect(() => {
        if (filters[0] === null) {
            return
        }
        setPage(1)
    }, [filters])

    return (
        <div id="main">
            <FilterBar filters={filters} setFilters={setFilters}></FilterBar>
            <InfiniteScroll
                dataLength={items.length}
                next={fetchData}
                hasMore={true} // Replace with a condition based on your data source
                loader={<p>Loading...</p>}
                endMessage={<p>No more data to load.</p>}
            >
                <Container>
                    {items.map(item => (
                        <div key={item["id"]}>
                            <Row className="item" onClick={() => onClick(item)}>
                                <Col xs={2} md={2} sm={2} className="item-image">
                                    <Image
                                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/api/v0/get-photo/${item["user_id"]}`}
                                        onError={( current ) => {
                                            current.target.onerror = null; // prevents looping
                                            current.target.src="/icons/profile.png";
                                        }}
                                        width={32}
                                        height={32}
                                        roundedCircle
                                    />
                                </Col>
                                <Col xs={9} md={9} sm={9}>
                                    <div className="item-text-container">
                                        <p className="item-text">{item["start_place"]} - {item["end_place"]}</p>
                                        <p className="item-text">{item["end_interval"]}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    <div ref={observerTarget}></div>
                </Container>

            </InfiniteScroll>
            {error && <p>Error: {error.message}</p>}
            <Modal isOpen={isOpen} parentSelector={() => document.querySelector('#main')} style={customStyles}>
                <SenderDetail item={item} setItem={setItem} userInviteLink={props.userInviteLink}/>
            </Modal>
        </div>
    );
}
