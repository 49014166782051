import {useFormContext} from "react-hook-form";
import {useState} from "react";
import "./TextArea.css"


export default function Input (
        { name, type, id, placeholder = null, label = null, defaultValue = "", width = "40%", enableValidation = true }
    ) {
    const { register } = useFormContext()
    const [validStyleClass, setValidStyleClass] = useState("valid-style");

    let labelElement = (<></>);
    if (label) {
        labelElement = (
            <div>
                <label htmlFor={id}>
                    {label}
                </label>
            </div>
        )
    }

    const validation = (value) => {
        if (!value) {
            setValidStyleClass("invalid-style")
            setTimeout(() => {
                setValidStyleClass("valid-style")
            }, 5000);
            return 'required'
        }

        setValidStyleClass("valid-style")
        return true
    }

    return (
        <>
            {labelElement}
            <input
                id={id}
                type={type}
                placeholder={placeholder}
                className={validStyleClass}
                defaultValue={defaultValue}
                style={{width: width}}
                {...register(name, {
                    validate: enableValidation ? validation : () => {}
                })}
            />
        </>
    )
}