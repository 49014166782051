import { SDKProvider } from '@tma.js/sdk-react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import { Container, Row} from "react-bootstrap";
import { Outlet } from "react-router-dom";
import {useEffect, useState} from "react";
import IconLink from "./IconLink";


function App() {
    window.Telegram.WebApp.expand();

    const [active, setActive] = useState(1);
    const [userInviteLink, setUserInviteLink] = useState("directLink");

    useEffect(() => {
        if (window.Telegram.WebApp.initDataUnsafe.user) {
            const user_id = window.Telegram.WebApp.initDataUnsafe.user.id
            fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/v0/get-user-invite-link/${user_id}`)
                .then(response => response.json()).then(json => {
                    if (json["invite_link"]) {
                        setUserInviteLink(json["invite_link"])
                    }
            })
        }
    }, [])

    return (
        <SDKProvider acceptCustomStyles debug>
            <Outlet context={[userInviteLink]}/>
            <div className="fixed-bottom">
                <Container className="iconsBar">
                    <Row>
                        <IconLink src="/icons/create.png" linkTo="create" index={0} active={active} setActive={setActive} text="Создать"></IconLink>
                        <IconLink src="/icons/sender.png" linkTo="senders" index={1} active={active} setActive={setActive} text="Отправители"></IconLink>
                        <IconLink src="/icons/traveler.png" linkTo="travelers" index={2} active={active} setActive={setActive} text="Путешественники"></IconLink>
                        <IconLink src="/icons/profile.png" linkTo="profile" index={3} active={active} setActive={setActive} text="Профиль"></IconLink>
                    </Row>
                </Container>
            </div>
        </SDKProvider>
    );
}

export default App;
