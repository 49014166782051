import {Col, Container, Image, Row} from "react-bootstrap";
import "./Profile.css";
import React from "react";


export default function Profile() {
    let user_id, first_name, photo_url, username, language_code;

    if (window.Telegram.WebApp.initDataUnsafe.user) {
        user_id = window.Telegram.WebApp.initDataUnsafe.user.id
        first_name = window.Telegram.WebApp.initDataUnsafe.user.first_name
        photo_url = window.Telegram.WebApp.initDataUnsafe.user.photo_url
        username = window.Telegram.WebApp.initDataUnsafe.user.username
        language_code = window.Telegram.WebApp.initDataUnsafe.user.language_code
    }

    if (user_id) {
        return (
            <div className="vertical-center" style={{height: "100vh"}}>
                <Container className="container-class">
                    <Row className="row-class-title">
                        <Col xs={3} md={3} sm={3}></Col>
                        <Col xs={6} md={6} sm={6} className="avatar-row">
                            <Image className="avatar" src={`${process.env.REACT_APP_SERVER_DOMAIN}/api/v0/get-photo/${user_id}`} width={128} height={128} roundedCircle/>
                        </Col>
                    </Row>
                    <Row className="row-class">
                        <Col xs={5} md={5} sm={5}>
                            <p className="text-area" >Name: </p>
                            <p className="text-area" >Username: </p>
                        </Col>
                        <Col xs={2} md={2} sm={2}></Col>
                        <Col xs={5} md={5} sm={5}>
                            <p className="text-area" style={{textAlign: "right"}}>{first_name}</p>
                            <p className="text-area" style={{textAlign: "right"}}>{username}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
