import {Col, Container, Row} from "react-bootstrap";
import {useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import Input from "./Input";
import "./Create.css";
import Textarea from "./Textarea";
import AutocompleteDropdown from "./AutocompleteDropdown";
import Modal from "react-modal";
import {useOutletContext} from "react-router-dom";


function Icon(props) {
    function onClickChange() {
        props.setCreatedItem(props.index)
    }

    return (
        <Col
            xs={4} md={4} xl={4}
            className={props.createdItem === props.index ? "icon-active" : "icon-inactive"}
            onClick={onClickChange}
            // style={{ backgroundColor: props.createdItem === props.index ? "#3478f7" : "#d4d4d7"}}
        >
            <p className={props.createdItem === props.index ? "icon-text-active" : "icon-text-inactive"}>
                {props.text}
            </p>
            {/*<Image src={props.src} alt="" width="40%"></Image><p>{props.text}</p>*/}
        </Col>
    )
}


export default function Create() {
    const [userInviteLink] = useOutletContext();
    const methods = useForm();
    const [createdItem, setCreatedItem] = useState("sender");
    const [activeAutocomplete, setActiveAutocomplete] = useState(null);
    const [inputStartPlaceValue, setInputStartPlaceValue] = useState([0, ""]);
    const [inputEndPlaceValue, setInputEndPlaceValue] = useState([0, ""]);
    const [inputMiddlePlace1, setInputMiddlePlace1] = useState([0, ""]);
    const [inputMiddlePlace2, setInputMiddlePlace2] = useState([0, ""]);
    const [inputMiddlePlace3, setInputMiddlePlace3] = useState([0, ""]);
    const [isOpen, setIsOpen] = useState(false);

    const customStyles = {
        content: {
            inset: 0,
            display: "flex",
            alignItems: "center",
        },
    };

    const onSubmit = methods.handleSubmit(data => {
        if (inputStartPlaceValue) {
            data["start_place"] = inputStartPlaceValue[1]
        }
        if (inputEndPlaceValue) {
            data["end_place"] = inputEndPlaceValue[1]
        }

        let urlPath = "want-send"

        if (createdItem === "traveler") {
            urlPath = "can-take"
            const middlePlacesInputs = [inputMiddlePlace1, inputMiddlePlace2, inputMiddlePlace3]
            let middle_places = []
            middlePlacesInputs.map((value) => {
                if (value[1]) {
                    middle_places.push(value[1])
                }
            })
            data["middle_places"] = middle_places
        }
        delete data["1_middle_place"]
        delete data["2_middle_place"]
        delete data["3_middle_place"]

        if (window.Telegram.WebApp.initDataUnsafe.user) {
            data["user_id"] = window.Telegram.WebApp.initDataUnsafe.user.id
            fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/v0/${urlPath}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(data)
            }).then(response => {
                window.ym(`${process.env.REACT_APP_YM}`, 'reachGoal', `${createdItem}-create`, {"inviteLink": userInviteLink});
            })
            methods.reset()
            setIsOpen(true)
        }
    })

    let travelerElems = (<></>)
    if (createdItem === "traveler") {
        let middlePlace1Element = (<></>)
        let middlePlace2Element = (<></>)
        let middlePlace3Element = (<></>)
        if (inputEndPlaceValue[1] || inputMiddlePlace1[1] || inputMiddlePlace2[1] || inputMiddlePlace3[1]) {
            middlePlace1Element = (<AutocompleteDropdown
                id="1_middle_place"
                placeholder="Транзитный город 1"
                activeAutocomplete={activeAutocomplete}
                setActiveAutocomplete={setActiveAutocomplete}
                inputValue={inputMiddlePlace1}
                setInputValue={setInputMiddlePlace1}
            />)
        }
        if (inputMiddlePlace1[1] || inputMiddlePlace2[1] || inputMiddlePlace3[1]) {
            middlePlace2Element = (<AutocompleteDropdown
                id="2_middle_place"
                placeholder="Транзитный город 2"
                activeAutocomplete={activeAutocomplete}
                setActiveAutocomplete={setActiveAutocomplete}
                inputValue={inputMiddlePlace2}
                setInputValue={setInputMiddlePlace2}
            />)
        }
        if (inputMiddlePlace2[1] || inputMiddlePlace3[1]) {
            middlePlace3Element = (<AutocompleteDropdown
                id="3_middle_place"
                placeholder="Транзитный город 3"
                activeAutocomplete={activeAutocomplete}
                setActiveAutocomplete={setActiveAutocomplete}
                inputValue={inputMiddlePlace3}
                setInputValue={setInputMiddlePlace3}
            />)
        }
        let departureDate = (
            <div>
                <Input name="departure_date" type="date" id="departure_date" label="Дата отправления:"/>
            </div>
        )
        travelerElems = (
            <>
                {middlePlace1Element}
                {middlePlace2Element}
                {middlePlace3Element}
                {departureDate}
            </>
        )
    }

    let senderElems = (<></>)

    if (createdItem === "sender") {
        senderElems = (
            <>
                <div>
                    <Input name="begin_interval" type="date" id="begin_interval" label="Укажите период, когда вам нужно отправить:"/>
                    <span> - </span>
                    <Input name="end_interval" type="date" id="end_interval"/>
                </div>
                <div>
                    <Textarea rows={3} id="what" name="what" required={true} placeholder='Что хотите отправить, пример: "Документы: тип, формат...", "Медикаменты: название, количество..."'/>
                </div>
                <div>
                    <Input name="weight" type="text" id="weight" placeholder="Вес"></Input>
                </div>
            </>
        );
    }

    let form = (<></>)
    if (createdItem) {
        form = (
            <Row style={{height: "68vh"}} className="vertical-center">
                <FormProvider {...methods}>
                    <form noValidate id="senderForm" autoComplete="off" method="post" onSubmit={e => e.preventDefault()}>
                        <div>
                            <Input
                                name="name"
                                type="text"
                                id="name"
                                placeholder="Имя"
                                defaultValue={window.Telegram.WebApp.initDataUnsafe.user ? window.Telegram.WebApp.initDataUnsafe.user.first_name : ""}
                            >
                            </Input>
                        </div>
                        <AutocompleteDropdown
                            id="start_place"
                            placeholder="Город отправления"
                            activeAutocomplete={activeAutocomplete}
                            setActiveAutocomplete={setActiveAutocomplete}
                            inputValue={inputStartPlaceValue}
                            setInputValue={setInputStartPlaceValue}
                            required={true}
                        />
                        <AutocompleteDropdown
                            id="end_place"
                            placeholder="Город назначения"
                            activeAutocomplete={activeAutocomplete}
                            setActiveAutocomplete={setActiveAutocomplete}
                            inputValue={inputEndPlaceValue}
                            setInputValue={setInputEndPlaceValue}
                            required={true}
                        />
                        {travelerElems}
                        {senderElems}
                        <div>
                            <Textarea rows={3} id="comment" name="comment" placeholder="Комментарий"/>
                        </div>
                        <button onClick={onSubmit}>Submit</button>
                    </form>
                </FormProvider>
            </Row>
        )
    }

    return (
        <>
            <div id="main-create" style={{overflowY: "auto"}}>
                <div className="vertical-center" style={{height: "90vh", minHeight: "90vh"}}>
                    <Container
                        onClick={(e) => {
                        if (!e.target.id.endsWith("_place") && e.target.className !== "autocomplete-elem") {
                            setActiveAutocomplete(null)
                        }}}
                    >
                        <Row style={{height: "12vh", alignItems: "center"}}>
                            <Col xs={1} md={1} xl={1}></Col>
                            <Icon src="/icons/create.png" index="sender" createdItem={createdItem} setCreatedItem={setCreatedItem} text="Отправитель"></Icon>
                            <Col xs={2} md={2} xl={2}></Col>
                            <Icon src="/icons/create.png" index="traveler" createdItem={createdItem} setCreatedItem={setCreatedItem} text="Путешественник"></Icon>
                        </Row>
                        {form}
                    </Container>
                </div>
                <Modal isOpen={isOpen} parentSelector={() => document.querySelector('#main-create')} style={customStyles}>
                    <Container className="container-class">
                        <p style={{overflow: "auto", whiteSpace: "normal"}}>
                            Спасибо! Твоя заявка сохранена и в скором времени будет опубликована администраторами!
                        </p>
                        <button onClick={() => setIsOpen(false)}>OK</button>
                    </Container>
                </Modal>
            </div>
            <div style={{height: "15vh"}}></div>
        </>
    )
}
