import {useFormContext} from "react-hook-form";
import {useState} from "react";
import "./TextArea.css"


export default function Textarea ({ name, id, required= false, placeholder = null}) {
    const { register } = useFormContext()
    const [validStyleClass, setValidStyleClass] = useState("valid-style");

    return (
        <>
            <textarea
                id={id}
                rows={3}
                placeholder={placeholder}
                className={validStyleClass}
                {...register(name, {
                    validate: value => {
                        if (!value && required) {
                            setValidStyleClass("invalid-style")
                            setTimeout(() => {
                                setValidStyleClass("valid-style")
                            }, 5000);
                            return 'required'
                        }

                        setValidStyleClass("valid-style")
                        return true
                    }
                })}
            />
        </>
    )
}