import {Col, Container, Image, Row} from "react-bootstrap";
import "./SenderDetail.css"
import React from "react";


export default function SenderDetail(props) {
    const dateText = props.item["type"] === "senders" ? "Доставить до" : "Дата вылета"
    const titleText = props.item["type"] === "senders" ? "Заказ на доставку" : "Доставка попутчиком"
    let greetingText = props.item["type"] === "senders" ? `
    Привет! Я нашел тебя через бота Get2Send https://t.me/get2send_bot. Могу доставить твою посылку. Твой пост: ${props.item["text"]}
    ` : `
    Привет! Я нашел тебя через бота Get2Send https://t.me/get2send_bot. Хотел бы передать через тебя посылку. Твой пост: ${props.item["text"]}
    `
    greetingText = greetingText.slice(0, 1000)

    function redirect() {
        window.ym(`${process.env.REACT_APP_YM}`, 'reachGoal', `redirect-to-${props.item["type"]}`, {"inviteLink": props.userInviteLink}, () => {
            window.location.href=`https://t.me/${props.item["username"]}?text=${greetingText}`;
        });
    }

    return (
        // <div className="vertical-center" style={{height: "100vh"}}>
            <Container className="container-class">
                <Row className="row-class-title">
                    <Col xs={2} md={2} sm={2} className="item-image">
                        <Image
                            src={props.item["photo_url"]}
                            onError={( current ) => {
                                current.target.onerror = null; // prevents looping
                                current.target.src="/icons/profile.png";
                            }}
                            width={32} height={32}
                            roundedCircle
                        />
                    </Col>
                    <Col xs={8} md={8} sm={8}>
                        <p style={{marginTop: "1rem", fontSize: "4vw"}} className="title-text"><b>{titleText}</b></p>
                        <p style={{marginBottom: "1rem"}} className="title-text">Создано: {props.item["created_datetime"]}</p>
                    </Col>
                    <Col xs={2} md={2} sm={2} className="item-image" onClick={() => props.setItem({})}>
                        <Image src="/icons/close.png" width={32} height={32} roundedCircle/>
                    </Col>
                </Row>
                <Row className="row-class">
                    <Col xs={5} md={5} sm={5}>
                        <p className="text-area" >{props.item["start_place"]}</p>
                        <p className="text-area" >{props.item["start_place_region"]}</p>
                    </Col>
                    <Col xs={2} md={2} sm={2}></Col>
                    <Col xs={5} md={5} sm={5}>
                        <p className="text-area" style={{textAlign: "right"}}>{props.item["end_place"]}</p>
                        <p className="text-area" style={{textAlign: "right"}}>{props.item["end_place_region"]}</p>
                    </Col>
                </Row>
                <Row className="row-class">
                    <p className="text-area">{props.item["text"]}</p>
                </Row>
                <Row className="row-class">
                    <p className="text-area">{dateText}: {props.item["end_interval"]}</p>
                </Row>
                <Row className="contact-button-container">
                    <button className="contact-button" onClick={redirect}>Написать</button>
                </Row>
            </Container>
        // </div>
    )
}
